@import "/src/styles/variables.scss";

.cardContainer {
	display: flex;
	align-items: center;
	gap: $flexGap32;
	justify-content: space-between;

	&.right {
		flex-direction: row;
	}
	&.left {
		flex-direction: row-reverse;
	}

	&:hover {
		.image {
			scale: 1.05;
			transition: 0.3s;
		}
	}
}

.imageContainer {
	height: 0;
	padding-bottom: 56.5%;
}

.categories {
	padding: 0 !important;

	&::before {
		display: none;
	}
}

.contentContainerMinimal {
	width: 100%;
	position: relative;
	padding-bottom: 40px;
}

.assetContainer {
	display: block;
	overflow: hidden;
	position: relative;
	flex: 0 0 calc(55% - 32px);
	border-radius: $borderRNormal;
	&:hover {
		.image {
			scale: 1.05;
			transition: 0.3s;
		}
	}
}

.readMore {
	color: $brandColor;
}

.image {
	height: auto;
	border-radius: $borderRNormal;
	transition: 0.3s;
	width: 100%;
}

.contentContainer {
	flex: 0 0 calc(45%);
	position: relative;
}

.minimalAsset {
	align-self: start;
	padding-right: 10%;
}
@media only screen and (max-width: $L) {
	.cardContainer {
		gap: $flexGap16;
	}

	.assetContainer {
		flex: 0 0 calc(55% - 16px);
	}
}

@media only screen and (max-width: $M) {
	.cardContainer {
		flex-direction: column;
		align-items: start;

		&.right {
			flex-direction: column;
		}

		&.left {
			flex-direction: column;
		}
	}

	.assetContainer {
		width: 100%;
	}

	.contentContainer {
		width: 100%;
	}
}
