.image {
	max-height: 120px;
	height: 100%;
	width: auto;
	margin: 0 40px;
}

.bottomButton {
	margin: 0 auto;
	text-align: center;
}

.container {
	overflow: hidden;
}
