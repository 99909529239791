@import "/src/styles/variables.scss";

.container {
	line-height: 0;
}

.videoBackground {
	width: 100%;
	margin: auto 0;

	&.mobile {
		aspect-ratio: 9 / 16;
		min-width: 100vw;
		display: none;
	}
}

@media screen and (max-width: $M) and (orientation: portrait) {
	.videoBackground {
		&.withoutMobile {
			display: none;
		}

		&.mobile {
			display: block;
		}
	}
}
