@import "/src/styles/variables.scss";

.formBox {
	flex: 0 0 50%;
	background: rgba(255, 255, 255, 0.9);
	border-radius: $borderRNormal;
}

.contentBox {
	flex: 0 0 45%;
}

.mainWrapper {
	display: flex;
	gap: $flexGap5P;
	justify-content: space-between;
	z-index: 11;
	position: relative;
}

@media screen and (max-width: $L) {
	.mainWrapper {
		flex-direction: column;
		gap: $flexGap48;
		width: 80%;
		margin: 0 auto;
	}

	.contentBox {
		flex: 0 0 100%;
	}

	.formBox {
		flex: 0 0 100%;
		border-radius: $borderRFull;
	}
}

@media screen and (max-width: $M) {
	.mainWrapper {
		width: 100%;
	}
}
