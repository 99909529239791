@import "/src/styles/variables.scss";

.cardContainer {
	position: relative;
	box-shadow: $boxShadowNormal;
	border-radius: $borderRNormal $borderRNormal 0 0;
	transition: 0.3s;
	// margin-bottom: 128px;
	background-color: $white;

	&:hover {
		box-shadow: $boxShadowHover;
		transition: 0.3s;

		.image {
			scale: 1.1;
			transition: 0.3s;
		}
	}
}

.imageContainer {
	border-bottom: 1px $grayScaleLvl5 solid;
	display: block;
	height: 0;
	overflow: hidden;
	padding-bottom: 60%;
	border-radius: $borderRNormal $borderRNormal 0 0;
	position: relative;
}

.readMore {
	color: $brandColor;
}

.image {
	height: 100%;
	position: absolute;
	transition: 0.3s;
	object-fit: cover;
	width: 100%;
	border-radius: $borderRNormal $borderRNormal 0 0;
}

.contentContainer {
	padding: 10px 10px 45px;
}

.buttonContainer {
	position: absolute;
	bottom: 0;
	left: 0;
	padding: 10px;
}

.categories {
	color: $brandColorShadeLvl1;

	&:hover {
		color: $brandColorShadeLvl2;
	}
}
