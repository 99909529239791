@import "/src/styles/variables.scss";

@include cardsImportant(2);

.cardContainer {
	justify-content: space-evenly;
}
.cardBox {
	text-align: center;
	max-width: 450px;
}
